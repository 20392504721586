import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { HiChevronDown } from "react-icons/hi";
import { topRecommendationsService } from "../../services/topRecommendationsService";
import { fetchPostsService } from "../../services/fetchPostsService";
import Container from "../../Components/Shared/Container/Container";
import Spinner from "../../Components/Shared/Spinner/Spiner";
import BlogCard from "../../Components/BlogCard/BlogCard";
import "./styles.css";

const { getTopRecommendations } = topRecommendationsService();
const { getPosts } = fetchPostsService();

const Blog = () => {
  const [topRecommendations, setTopRecommendations] = useState([]);
  const [posts, setPosts] = useState(null);
  const [active, setActive] = useState(5);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState();
  const [category, setCategory] = useState("1");
  const [getCat, setGetCat] = useState(false);

  const toggleActiveTag = (id) => {
    setActive(id);
    setLoading(true);
    getPosts(id)
      .then((res) => {
        const { data } = res;
        // console.log(data);
        setPosts(data.attributes.recommendations.data);
        setLoading(false);
        // setError("");
      })
      .catch((error) => {
        setLoading(false);
        // setError("something went wrong");
        console.log(error);
      });
  };

  const handleSelectCategory = (e) => {
    e.preventDefault();
    setCategory(e.target.value);
    setGetCat(true);
  };

  const fetchCat = (id) => {
    if (getCat) {
      setLoading(true);
      getPosts(id)
        .then((res) => {
          const { data } = res;
          console.log(data.attributes.recommendations.data);
          setPosts(data.attributes.recommendations.data);
          setLoading(false);
          setGetCat(false);
          // setError("");
        })
        .catch((error) => {
          setLoading(false);
          // setError("something went wrong");
          console.log(error);
        });
    }
  };

  const removeFirstBlogPost = (arr) => {
    return arr.filter((item) => arr.indexOf(item) !== 0);
  };

  const removeUnpublishedItem = (arr) => {
    return arr.filter((item) => item.attributes.publishedAt !== null);
  };

  useEffect(() => {
    let mounted = true;

    const fetchTopRecommendations = async () => {
      const response = await getTopRecommendations();

      if (mounted && response) {
        setTopRecommendations(response.data);
      }
    };

    const fetchInitialPosts = async () => {
      const response = await getPosts(5);

      if (mounted && response) {
        // console.log("initial posts retrieved");
        const { data } = response;
        // console.log(data.attributes.recommendations.data);
        setPosts(data.attributes.recommendations.data);
        setLoading(false);
        // setError("");
      } else {
        setLoading(false);
        // setError("something went wrong");
      }
    };

    fetchTopRecommendations();
    fetchInitialPosts();
    fetchCat(category);

    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  return (
    <>
      <Helmet>
        <title>Eden Footrpint | Blog</title>
      </Helmet>
      <div className="blog-hero-section">
        <h1>Blog</h1>
      </div>
      <div className="blog-section">
        <Container>
          <div className="mobile-tags-section">
            <p>category</p>
            <select
              value={category}
              onChange={(e) => {
                handleSelectCategory(e);
              }}
            >
              {removeUnpublishedItem(topRecommendations).map(
                (topRecommendation) => (
                  <option
                    key={topRecommendation.id}
                    value={topRecommendation.id}
                  >
                    {topRecommendation.attributes.name}
                  </option>
                )
              )}
            </select>
            <div className="custom-arrow">
              <HiChevronDown />
            </div>
          </div>
          <div className="blog-tags">
            <ul>
              {topRecommendations.map((topRecommendation) => (
                <li
                  key={topRecommendation.id}
                  className={
                    active === topRecommendation.id ? "active-tag" : undefined
                  }
                  onClick={() => toggleActiveTag(topRecommendation.id)}
                >
                  {topRecommendation.attributes.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="blog-grid">
            {loading && <Spinner />}
            {!loading &&
              posts &&
              removeUnpublishedItem(removeFirstBlogPost(posts)).map((post) => (
                <BlogCard
                  key={post.id}
                  id={post.id}
                  title={post.attributes.name}
                  img={post.attributes.imageUrl}
                  date={post.attributes.publishedAt}
                />
              ))}
            {posts === null || (posts.length <= 0 && <p>no available posts</p>)}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Blog;
