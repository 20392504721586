// import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookSquare,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import "./style.css";

import logo from "../../assets/img/new-assets/primary-logo.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="footer-container">
        <div className="footer-grid">
          <div className="footer-grid-1">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            <p className="copyright">
              Copyright {currentYear} <br /> Eden Footprint Technologies. <br />{" "}
              All Rights Reserved
            </p>
          </div>
          <div className="footer-grid-2">
            <h3 className="grid-header">company</h3>
            <ul className="grid-item-list">
              <li>
                <a href="/">about</a>
              </li>
              <li>
                <a href="/">projects</a>
              </li>
              <li>
                <a href="/">blog</a>
              </li>
              <li>
                <a href="/">jobs</a>
              </li>
            </ul>
          </div>
          <div className="footer-grid-3">
            <h3 className="grid-header">legal</h3>
            <ul className="grid-item-list">
              <li>
                <a href="/">imprint</a>
              </li>
              <li>
                <a href="/">terms of service</a>
              </li>
              <li>
                <a href="/">privacy policy</a>
              </li>
              <li>
                <a href="/">cookie policy</a>
              </li>
            </ul>
          </div>
          <div className="footer-grid-4">
            <h3 className="grid-header">follow us</h3>
            <div className="footer-social-icons">
              <div className="footer-social-icon">
                <FontAwesomeIcon icon={faTwitter} />
              </div>
              <div className="footer-social-icon">
                <div className="footer-social-icon">
                  <FontAwesomeIcon icon={faFacebookSquare} />
                </div>
              </div>
              <div className="footer-social-icon">
                <div className="footer-social-icon">
                  <FontAwesomeIcon icon={faLinkedin} />
                </div>
              </div>
              <div className="footer-social-icon">
                <div className="footer-social-icon">
                  <FontAwesomeIcon icon={faInstagram} />
                </div>
              </div>
            </div>
            <p className="mobile-copyright">
              Copyright {currentYear} Eden Footprint Technologies. All Rights
              Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
