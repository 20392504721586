import "./style.css";

import mockup from "../../../assets/img/SectionTwo/Fund-Projects-Mockup.png";
import logo from "../../../assets/img/new-assets/new-favicon.png";
import appstoreImg from "../../../assets/img/SectionTwo/appstore.svg";
import googlePlay from "../../../assets/img/SectionTwo/playstore.svg";

const SectionTwo = () => {
  return (
    <div className="section-container">
      <div className="top-section">
        <div className="top-section-mockup-img">
          <img src={mockup} alt="" />
        </div>
        <div className="eden-logo">
          <img src={logo} alt="" />
        </div>
      </div>
      <div className="bottom-section">
        <div className="bottom-section-container">
          <div className="section-download-btns">
            <a href="/" target="_blank" rel="noreferrer">
              <img src={googlePlay} alt="" />
            </a>
            <a href="/" target="_blank" rel="noreferrer">
              <img src={appstoreImg} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
