import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import Logo from "../../../assets/img/new-assets/secondary-logo.png";

const Navbar = () => {
  const [showNav, setShowNav] = useState(false);
  const [active, setActive] = useState(false);
  const location = useLocation();

  const handleShowNav = () => {
    setShowNav(!showNav);
    setActive(true);
  };

  const hideNav = () => {
    setShowNav(false);
  };

  const changeBackground = () => {
    if (window.scrollY >= 83.03) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  useEffect(() => {
    let mounted = true;

    window.addEventListener("scroll", changeBackground);

    const renderNavBg = () => {
      if (mounted && location.pathname.includes("/blog")) {
        setActive(true);
      }
    };

    renderNavBg();

    return () => {
      window.removeEventListener("scroll", changeBackground);
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.scrollY]);

  return (
    <div className={`nav-section ${active && "black-bg"}`}>
      <Link to="/" className="nav-logo" onClick={hideNav}>
        <img src={Logo} alt="eden footprint logo" />
      </Link>
      <div className={`nav-links ${showNav ? "active" : null}`}>
        <ul>
          <li onClick={hideNav}>
            <Link to="/#about-section">about</Link>
          </li>
          <li onClick={hideNav}>
            <Link to="/#faq-section">FAQ</Link>
          </li>
          {/* <li onClick={hideNav}>
            <Link to="/blog">blog</Link>
          </li> */}
        </ul>
        <Link to="#" className="nav-get-app-btn">
          get the app
        </Link>
      </div>
      <div className="hamburger" onClick={handleShowNav}>
        {showNav ? (
          <FontAwesomeIcon icon={faClose} />
        ) : (
          <FontAwesomeIcon icon={faBars} />
        )}
      </div>
    </div>
  );
};

export default Navbar;
